import { render, staticRenderFns } from "./AppManualNotificationOne.vue?vue&type=template&id=5662deb4&scoped=true&"
import script from "./AppManualNotificationOne.vue?vue&type=script&lang=js&"
export * from "./AppManualNotificationOne.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5662deb4",
  null
  
)

export default component.exports