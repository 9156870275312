import Auth from '@/services/parts/auth';
import Users from '@/services/parts/users';
import Roles from '@/services/parts/roles';
import { Guides, Policies, Prizes, Socials } from '@/services/parts/informations';
import Offers from '@/services/parts/offers';
import Levels from '@/services/parts/levels';
import Gifts from '@/services/parts/gifts';
import Categories from '@/services/parts/categories';
import Logs from '@/services/parts/logs';
import Subscribers from '@/services/parts/subscribers';
import Statistics from '@/services/parts/statistics';
import {
    InputTypes,
    Questions,
    Surveys,
    VoteAnswers,
    VoteOrganizations
} from '@/services/parts/questionnaire';
import { PaynetServices, PaynetStatistics } from '@/services/parts/paynet';
import News from '@/services/parts/news';
import Coin from '@/services/parts/coin';
import ImageUploader from '@/services/parts/image-uploader';
import Ads from '@/services/parts/ads';
import Regions from '@/services/regions/region.service';
import City from '@/services/city/city.service';
import ProductCategory from '@/services/product-category/product.category.service';
import FAQ from '@/services/faq/faq.service';
import Warehouse from '@/services/warehouse/warehouse.service';
import Notifications from '@/services/notifications/notifications.service';
import Policy from '@/services/policy/policy.service';
import TelegramChannels from '@/services/ads/telegram.channels.service';
import Tournament from '@/services/tournament/tournament.service';
import LampSubscribers from '@/services/lamp-subscribers/lamp.subscribers';
import Languages from '@/services/languages/languages.service';
import Translations from '@/services/translations/translations.service';
import Flash from '@/services/flash/flash';
import Banners from '@/services/banners/banners.service';
import Servers from '@/services/servers/servers.service';
import Control from '@/services/control/control.service';
import Test from '@/services/parts/test';
import Charges from '@/services/new-chapter/charges.service';
import Subscription from '@/services/new-chapter/subscription.service';
import Packages from '@/services/new-chapter/packages.service';
import Rate from '@/services/new-chapter/rate.service';
import InfinityGroups from '@/services/new-chapter/infinity-groups.service';
import InfinityProducts from '@/services/new-chapter/infinity-products.service';
import RandomizerCategories from '@/services/new-chapter/randomizer-categories.service';
import RandomizerCategorySteps from '@/services/new-chapter/randomizer-category-steps.service';
import KubeServers from '@/services/kube-servers/kube-servers.service';
import AdministrationUzmobile from '@/services/administration/uzmobile.service';
import AdministrationUcell from '@/services/administration/ucell.service';
import NewLevel from '@/services/new-level/level.service';
import NewLevelPrize from '@/services/new-level/prize.service';
import NewLevelActions from '@/services/new-level/actions.service';
import SubscriptionV2 from '@/services/subscriber/subscriptionV2.service';
import StatisticsV2 from '@/services/new-statistics/statistics.service';
import Purchases from '@/services/new-chapter/purchases.service';
import RandomizerCategoriesFlash from '@/services/new-chapter/randomizer-categories-flash.service';
import PolicyV2Service from '@/services/policy/policyV2.service';
import HomeUsersStatistics from '@/services/home-statistics/users.service';
import HomeChargesStatistics from '@/services/home-statistics/charges.service';
import HomeInvoiceStatistics from '@/services/home-statistics/invoice.service';
import HomePackageStatistics from '@/services/home-statistics/packages.service';
import ScenarioPrize from '@/services/scenario/scenarioPrize.service';
import ScenarioService from '@/services/scenario/scenario.service';
import NotificationTemplatesService from '@/services/notificationV2/notification-templates.service';
import NotificationPortionService from '@/services/notificationV2/notification-portion.service';
import ScenarioNotificationService from '@/services/scenario/scenarioNotification.service';
import PaynetV2Service from '@/services/paynet/paynetV2.service';
import FastCharges from '@/services/new-chapter/fast-charges.service';
import Segmentation from '@/services/segmentation/segmentation.service';
import USSD from '@/services/ussd/ussd.service';
import DashboardActivity from '@/services/dashboard-activity/dashboard-activity.service';
import SubscribersV2 from '@/services/subscriber/subscriberV2.service';
import NotificationManualService from '@/services/notificationV2/notification-manual.service';
import RandomizerCategoriesStock from '@/services/new-chapter/randomizer-stock.service';
import KubeServersV2 from '@/services/kube-servers-v2/kube-servers-v2.service';
import StockTemplatesService from '@/services/stock/stock-templates.service';
import StockCategoryService from '@/services/stock/stock-category.service';
import StockHistoryService from '@/services/stock/stock-history.service';
import ReferralPrizeService from '@/services/referral/referral-prize.service';
import FlashStatisticsService from '@/services/flash/flash-statistics.service';
import OtherStatistics from '@/services/home-statistics/other-statistics.service';
import RoadmapService from '@/services/new-chapter/roadmap.service';
import AlertModal from '@/services/alert/alert.service';
import RoadmapSeasonGtwApi from '@/services/roadmap/roadmap-season.gtw.service';
import RoadmapLevelGtwApi from '@/services/roadmap/roadmap-level.gtw.service';
import RoadmapTaskGtwApi from '@/services/roadmap/roadmap-task.gtw';
import SandboxService from '@/services/new-chapter/sandbox.service';
import BonusStatisticsService from '@/services/all-statistics/bonus-statistics.service';
import FormStatisticsService from '@/services/all-statistics/form-statistics.service';
import UssdMenuService from '@/services/ussd/ussd-menu.service';
import UssdTextsService from '@/services/ussd/ussd-texts.service';
import FeedbackPollsService from '@/services/feedback/feedback-polls.service';
import FeedbackOptionsService from '@/services/feedback/feedback-options.service';
import UssdStatisticsService from '@/services/ussd/ussd-statistics.service';
import Cards from '@/services/cards/cards.service';
import CardManagementService from '@/services/cards/card-management.service';
import BlacklistService from '@/services/blacklist/blacklist.service';
import WalletBalanceService from '@/services/wallet-balance/wallet-balance.service';
import DealersService from '@/services/dealers/dealers-service';
import RoadmapAvatarGtwApi from '@/services/roadmap/roadmap-avatar.gtw';
import DealerAccountsService from '@/services/dealers/dealer-accounts.service';
import DealerServiceService from '@/services/dealers/dealer-service.service';
import DealerConstantsService from '@/services/dealers/dealer-constants.service';
import BundleService from '@/services/new-chapter/bundle.service';
import SpecialOffersService from '@/services/stock/special-offers.service';
import LevelScenario from '@/services/scenario/level.scenario';

export default {
    auth: new Auth('/auth'),
    policies: new Policies('/policies'),
    prizes: new Prizes('/prizes'),
    guides: new Guides('/guides'),
    socials: new Socials('/socials'),
    users: new Users('/users'),
    roles: new Roles('/roles'),
    offers: new Offers('/offers'),
    levels: new Levels('/levels'),
    gifts: new Gifts('/gifts'),
    categories: new Categories('/categories'),
    logs: new Logs('/log'),
    subscribers: new Subscribers('/subscribers'),
    statistics: new Statistics(),
    organizations: new VoteOrganizations('/vote/organizations'),
    surveys: new Surveys(),
    questions: new Questions(),
    answers: new VoteAnswers(),
    inputTypes: new InputTypes('/vote/input-types'),
    paynetStatistics: new PaynetStatistics('/statistics/paynet'),
    paynetServices: new PaynetServices('/paynet'),

    // IMAGE UPLOADER
    // imageUploader: new ImageUploader('/upload/'),
    imageUploader: new ImageUploader('/uploads/'),

    // VERSION 2
    news: new News(),
    coin: new Coin(),
    ads: new Ads(),
    regions: new Regions(),
    city: new City(),
    productCategory: new ProductCategory(),
    faq: new FAQ(),
    warehouse: new Warehouse(),
    notifications: new Notifications(),
    policy: new Policy(),
    channels: new TelegramChannels(),
    tournament: new Tournament(),
    lampSubscribers: new LampSubscribers(),
    languages: new Languages(),
    translations: new Translations(),
    flash: new Flash(),
    banners: new Banners(),
    servers: new Servers(),
    control: new Control(),
    test: new Test(),

    //  NEW CHAPTER
    subscription: new Subscription(),
    billingCharges: new Charges(),
    billingPackages: new Packages(),
    billingRate: new Rate(),
    infinityGroups: new InfinityGroups(),
    infinityProducts: new InfinityProducts(),
    randomizerCategories: new RandomizerCategories(),
    randomizerCategorySteps: new RandomizerCategorySteps(),
    randomizerCategoriesFlash: new RandomizerCategoriesFlash(),
    randomizerCategoriesStock: new RandomizerCategoriesStock(),

    // KUBE SERVERS
    kubeServers: new KubeServers(),

    // ADMINISTRATION
    administrationUzmobile: new AdministrationUzmobile(),
    administrationUcell: new AdministrationUcell(),

    // NEW LEVEL
    newLevel: new NewLevel(),
    newLevelPrize: new NewLevelPrize(),
    newLevelActions: new NewLevelActions(),

    // V2 SUBSCRIPTION
    subscriptionV2: new SubscriptionV2(),

    // V2 STATISTICS
    statisticsV2: new StatisticsV2(),

    // VIP
    purchases: new Purchases(),

    // POLICY
    policyV2: new PolicyV2Service(),

    // NEW HOME STATISTICS
    homeUsersStatistics: new HomeUsersStatistics(),
    homePackagesStatistics: new HomePackageStatistics(),
    homeInvoiceStatistics: new HomeInvoiceStatistics(),
    homeChargesStatistics: new HomeChargesStatistics(),
    otherStatistics: new OtherStatistics(),

    // SCENARIO
    scenarioPrizeApi: new ScenarioPrize(),
    scenarioLevelApi: new LevelScenario(),
    scenarioNotificationApi: new ScenarioNotificationService(),
    scenario: new ScenarioService(),

    // NOTIFICATION V2
    notificationTemplatesApi: new NotificationTemplatesService(),
    notificationPortionApi: new NotificationPortionService(),
    notificationManualApi: new NotificationManualService(),

    //PAYNET V2
    paynetApiV2: new PaynetV2Service(),

    // FAST CHARGES
    fastCharges: new FastCharges(),

    //SEGMENTATION
    segmentationApi: new Segmentation(),

    //USSD
    ussdApi: new USSD(),

    // DASHBOARD ACTIVITY
    dashboardActivityApi: new DashboardActivity(),

    // SUBSCRIBER V2
    subscriberV2Api: new SubscribersV2(),

    // KUBE SERVERS V2
    kubeServersApiV2: new KubeServersV2(),

    // STOCK
    stockTemplateApi: new StockTemplatesService(),
    stockCategoryApi: new StockCategoryService(),
    stockHistoryApi: new StockHistoryService(),

    // REFERRAL
    referralPrizeApi: new ReferralPrizeService(),

    // FLASH STATISTICS
    flashStatisticsApi: new FlashStatisticsService(),

    // ROADMAP
    roadmapApi: new RoadmapService(),
    roadmapSeasonGtwApi: new RoadmapSeasonGtwApi(),
    roadmapLevelGtwApi: new RoadmapLevelGtwApi(),
    roadmapTaskGtwApi: new RoadmapTaskGtwApi(),
    roadmapAvatarGtwApi: new RoadmapAvatarGtwApi(),

    // ALERT MODAL
    alertModalApi: new AlertModal(),

    // SANDBOX
    sandboxServiceApi: new SandboxService(),

    // ALL STATISTICS
    bonusStatisticsApi: new BonusStatisticsService(),
    formStatisticsApi: new FormStatisticsService(),

    //USSD MANAGEMENT
    ussdMenuApi: new UssdMenuService(),
    ussdTextsApi: new UssdTextsService(),
    ussdStatisticsApi: new UssdStatisticsService(),

    // FEEDBACK
    feedbackPollsApi: new FeedbackPollsService(),
    feedbackOptionsApi: new FeedbackOptionsService(),

    // CARDS
    cardsApi: new Cards(),
    cardsManagementApi: new CardManagementService(),

    // BLACKLIST
    blackListApi: new BlacklistService(),

    // WALLET BALANCE
    walletBalanceApi: new WalletBalanceService(),

    // DEALERS
    dealersApi: new DealersService(),
    dealerAccountsApi: new DealerAccountsService(),
    dealerServiceApi: new DealerServiceService(),
    dealerConstantsApi: new DealerConstantsService(),
    bundleApi: new BundleService(),

    // SPECIAL OFFERS
    specialOffersApi: new SpecialOffersService()
};
