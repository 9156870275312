<script>
import { computed, getCurrentInstance, ref, watch } from '@vue/composition-api';

import { BCol, BPagination, BRow } from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

import services from '@/services';
import { formatToPrice } from '@/util/number';
import { MERCHANT_ID } from '@/constants/names';
import {
    dateFormatWithDot,
    formatDateToHMS,
    formatDateWithFiveHours
} from '@/util/date/calendar.util';
import { isString } from '@/util/inspect.util';
export default {
    name: 'SubscriberGameMonitoring',
    methods: { formatDateWithFiveHours, formatToPrice },
    components: {
        BPagination,
        BCol,
        BRow,
        AppTimeline,
        AppTimelineItem
    },
    setup() {
        const vm = getCurrentInstance();
        const { $route } = vm.proxy;
        const appTimeLineRef = ref(null);
        const list = ref([]);
        const pagination = ref({
            current: 1,
            totalItem: '',
            perPage: 10
        });

        const showPagination = computed(() => {
            return list.value.length;
        });

        watch(() => pagination.value.current, findAll);

        async function findAll() {
            const rsp = await services.scoreHistory.findAll({
                page: pagination.value.current,
                limit: pagination.value.perPage,
                user_id: parseInt($route.params.id),
                merchant_id: parseInt(MERCHANT_ID)
            });

            list.value = rsp.data.result;
            pagination.value = rsp.data.pagination;
            console.log(list);
        }

        function formatCreatedAt(d) {
            return formatDateToHMS(d) + '  ' + dateFormatWithDot(d, { reverse: false });
        }

        findAll();

        return {
            appTimeLineRef,
            list,
            pagination,
            showPagination,
            findAll,
            formatCreatedAt
        };
    }
};
</script>

<template>
    <BCard>
        <AppTimeline ref="appTimeLineRef">
            <AppTimelineItem
                v-for="item in list"
                :key="item.id + item.created_at"
                :icon="item.coin_gained ? 'CheckIcon' : 'XIcon'"
                :variant="item.coin_gained ? 'bg-success' : 'bg-warning'"
            >
                <div class="text-bold">
                    <span class="mr-1">{{ $t('tournament.score') }}:</span>
                    <span class="text-primary">{{ formatToPrice(item.score) }}</span>
                </div>
                <div class="custom-margin-top">
                    <span class="text-bold mr-1">{{ $t('titles.game') }}:</span>
                    <span class="text-bold bg-primary text-white px-1 game-name">
                        <span class="text-warning" style="margin-right: 0.3rem"
                            >#{{ item.game_id }}</span
                        >
                        <span>{{ item.game_name }}</span>
                    </span>
                </div>
                <div class="custom-margin-top text-bold">
                    <span class="mr-1">{{ $t('game_monitoring.started_time') }}:</span>
                    <span class="text-warning">{{ formatDateWithFiveHours(item.created_at) }}</span>
                </div>
                <div class="custom-margin-top text-bold">
                    <span class="mr-1">{{ $t('game_monitoring.duration') }}:</span>
                    <span class="text-primary font-medium-3">{{ item.time_played }}</span>
                    <span style="margin-left: 0.5rem">{{ $t('titles.second') }}</span>
                </div>
                <div class="custom-margin-top text-bold">
                    <span class="mr-1">{{ $t('game_monitoring.limit_over') }}:</span>
                    <feather-icon
                        :class="[item.limit_over ? 'text-success' : 'text-danger']"
                        :icon="item.limit_over ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </div>
                <div class="custom-margin-top text-bold">
                    <span class="mr-1">{{ $t('game_monitoring.max_point') }}:</span>
                    <feather-icon
                        :class="[item.max_point ? 'text-success' : 'text-danger']"
                        :icon="item.max_point ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </div>
                <div class="custom-margin-top text-bold">
                    <span class="mr-1">{{ $t('game_monitoring.time_limit') }}:</span>
                    <feather-icon
                        :class="[item.time_limit ? 'text-success' : 'text-danger']"
                        :icon="item.time_limit ? 'CheckIcon' : 'XIcon'"
                        size="20"
                    />
                </div>
                <BDdDivider class="mt-2" />
            </AppTimelineItem>
        </AppTimeline>

        <b-row class="mt-2">
            <b-col cols="12" class="d-flex justify-content-center">
                <b-pagination
                    size="lg"
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="center mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </BCard>
</template>

<style lang="scss" scoped>
.game-name {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 0.5rem;
}

.custom-margin-top {
    margin-top: 0.5rem;
}
</style>
