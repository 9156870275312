import { axiosV5 } from '@/services/core/base';
import { MERCHANT_ID } from '@/constants/names';
import CoreV2 from '@/services/core/coreV2';

class Purchases extends CoreV2 {
    constructor() {
        super(axiosV5());
    }

    dashboardVersion() {
        return Number(MERCHANT_ID) === 2 ? 'infinity/dashboardGf/' : 'infinity/dashboardAl/';
    }

    fetchAllItems(params) {
        return this.post(this.dashboardVersion() + 'purchase/getAll', params);
    }

    fetchOneItem(id) {
        return this.get(this.dashboardVersion() + `purchase/get/${id}`);
    }

    createItem(body) {
        return this.post(this.dashboardVersion() + 'purchase/create', body);
    }

    updateItem(id, body) {
        return this.post(this.dashboardVersion() + `purchase/update/${id}`, body);
    }

    deleteItem(id) {
        return this.delete(this.dashboardVersion() + `purchase/delete/${id}`);
    }

    fetchPurchaseHistory(params) {
        return this.post(this.dashboardVersion() + 'history/activity', params);
    }
}

export default Purchases;
