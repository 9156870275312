<script>
import { BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import api from '@/services/api';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';

export default {
    name: 'AppManualNotificationOne',
    components: { BCol, BFormGroup, BRow, vSelect, BFormInput },
    data() {
        return {
            templatesList: [],
            requestBody: {
                template_id: null,
                service: 'manual',
                params: [],
                title_params: [],
                user_id: null
            }
        };
    },
    async mounted() {
        await this.getTemplatesList();
    },
    methods: {
        checkLocales,
        async getTemplatesList() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                page: 1,
                limit: 2000
            };
            try {
                const { data } = await api.notificationTemplatesApi.fetchAllItems(body);
                this.templatesList = data.result;
            } catch (e) {
                console.error(e);
            }
        },
        async sendManualNotificationToOne() {
            const body = {
                template_id: this.requestBody.template_id,
                service: this.requestBody.service,
                params: this.requestBody.params,
                title_params: this.requestBody.title_params,
                user_id: this.requestBody.user_id,
                merchant_id: parseInt(MERCHANT_ID)
            };
            this.$refs.validationForm.validate().then(async (success) => {
                if (success) {
                    try {
                        const { data } = await api.notificationManualApi.sendNotificationToOne(
                            body
                        );
                        console.log(data, data);
                    } catch (e) {
                        console.error(e);
                    } finally {
                        await this.clearFields();
                    }
                }
            });
        },

        clearFields() {
            this.requestBody = {
                template_id: null,
                service: 'manual',
                params: [],
                title_params: [],
                user_id: null
            };
        }
    }
};
</script>

<template>
    <div class="manual__notification">
        <ValidationObserver ref="validationForm">
            <b-row>
                <!--     TEMPLATE ID     -->
                <div class="col-12 col-md-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('titles.template')"
                        rules="required"
                    >
                        <b-form-group :label="$t('titles.template')">
                            <v-select
                                :placeholder="$t('choose.template')"
                                :options="templatesList"
                                v-model="requestBody.template_id"
                                :reduce="(option) => option.id"
                                label="title"
                                :clearable="false"
                            >
                                <template v-slot:option="option">
                                    {{ checkLocales(option.title) }}
                                </template>

                                <template v-slot:selected-option="option">
                                    {{ checkLocales(option.title) }}
                                </template>
                            </v-select>
                        </b-form-group>
                        <p v-if="errors" class="validation__red">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>

                <!--    USER ID    -->
                <div class="col-12 col-md-6">
                    <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('titles.user_id')"
                        rules="required"
                    >
                        <b-form-group :label="$t('titles.user_id')">
                            <b-form-input
                                v-model="requestBody.user_id"
                                :placeholder="$t('enter.user_id')"
                                :state="errors.length > 0 ? false : null"
                                size="md"
                            />
                        </b-form-group>
                        <span class="validation__red">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <!--     PARAMS TITLE     -->
                <div class="col-12 col-md-6">
                    <b-form-group :label="$t('titles.title_params')">
                        <v-select
                            :placeholder="$t('titles.title_params')"
                            v-model="requestBody.title_params"
                            :reduce="(option) => option"
                            taggable
                            multiple
                            :clearable="false"
                        />
                    </b-form-group>
                </div>

                <!--     PARAMS DESCRIPTION     -->
                <div class="col-12 col-md-6 mt-1 mt-md-0">
                    <b-form-group :label="$t('titles.description_params')">
                        <v-select
                            :placeholder="$t('titles.description_params')"
                            v-model="requestBody.params"
                            :reduce="(option) => option"
                            taggable
                            multiple
                            :clearable="false"
                        />
                    </b-form-group>
                </div>
            </b-row>
        </ValidationObserver>

        <div class="col-12 px-0 mt-1">
            <b-button @click="sendManualNotificationToOne" class="float-right" variant="success">
                {{ $t('button.send') }}
            </b-button>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
