import Vue from 'vue';
import VueRouter from 'vue-router';

import home from './routes/home';
import pages from '@/router/routes/pages';
import usersManagement from '@/router/routes/usersManagement';
import informations from '@/router/routes/informations';
import levels from '@/router/routes/levels';
import subscribersActivity from '@/router/routes/subscribers';
import questionnaire from '@/router/routes/questionnaire';
import paynet from '@/router/routes/paynet';
import coin from '@/router/routes/coin';
import adsRoute from '@/router/routes/ads';
import avatar from '@/router/routes/avatar';
import tournament from '@/router/routes/tournament';
import others from '@/router/routes/others';

import AppCoinOrderList from '@/views/coin/order/AppCoinOrderList.vue';
import OrderView from '@/views/coin/order/OrderView.vue';
import AppCoinWarehouse from '@/views/coin/AppCoinWarehouse.vue';
import Notifications from '@/views/notifications/Notifications.vue';
import ServerKey from '@/views/notifications/ServerKey.vue';
import AppLanguages from '@/views/languages/AppLanguages.vue';
import AppTranslations from '@/views/translations/AppTranslations.vue';
import NewChapter from '@/router/routes/new-chapter';
import kubeServers from '@/router/routes/kube-servers';
import administration from '@/router/routes/administration';
import NewLevel from '@/router/routes/new-level';
import Scenario from '@/router/routes/scenario';
import NotificationV2 from '@/router/routes/notificationV2';
import Segmentation from '@/router/routes/segmentation';
import dashboardActivity from '@/router/routes/dashboard-activity';
import stock from '@/router/routes/stock';
import roadmap from '@/router/routes/roadmap';
import ussdManagement from '@/router/routes/ussd-management';
import feedback from '@/router/routes/feedback';
import cards from '@/router/routes/cards';
import walletBalance from '@/router/routes/wallet-balance';
import dealers from '@/router/routes/dealers';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    // eslint-disable-next-line no-undef
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        };
    },
    routes: [
        {
            path: '/',
            redirect: { name: 'HomeV2' }
        },
        ...avatar,
        ...pages,
        ...home,
        ...usersManagement,
        ...informations,
        ...levels,
        ...subscribersActivity,
        ...paynet,
        ...questionnaire,
        ...coin,
        ...adsRoute,
        ...tournament,
        ...others,
        ...NewChapter,
        ...Scenario,
        ...NotificationV2,
        ...kubeServers,
        ...administration,
        ...Segmentation,
        ...NewLevel,
        ...dashboardActivity,
        ...stock,
        ...roadmap,
        ...ussdManagement,
        ...feedback,
        ...cards,
        ...walletBalance,
        ...dealers,
        // *===============================================---*
        // *--------- CHAT  ---------------------------------------*
        // *===============================================---*
        {
            path: '/chat/alchiroq/:id?',
            name: 'alchiroq-chat',
            component: () => import('@/views/chat/Chat.vue'),
            meta: {
                contentRenderer: 'sidebar-left',
                contentClass: 'chat-application',
                permission: 'view'
            }
        },
        {
            path: '/chat/oltin-baliq/:id?',
            name: 'oltin-baliq-chat',
            component: () => import('@/views/chat/Chat.vue'),
            meta: {
                contentRenderer: 'sidebar-left',
                contentClass: 'chat-application',
                permission: 'view'
            }
        },
        {
            path: '/coin/order',
            name: 'coin-order',
            component: AppCoinOrderList,
            meta: {
                section: 'orders',
                permission: 'orders'
            }
        },
        {
            path: '/coin-order-view/:id?',
            name: 'coin-order-view',
            component: OrderView,
            meta: {
                section: 'orders',
                permission: 'orders'
            }
        },
        {
            path: '/warehouse',
            name: 'warehouse',
            component: AppCoinWarehouse,
            meta: {
                section: 'warehouse',
                permission: 'warehouse'
            }
        },

        {
            path: '*',
            redirect: {
                name: 'Error 404'
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    const AUTH_TOKEN = localStorage.getItem('accessToken');
    if (to.name === 'Auth Login') return next();
    if (AUTH_TOKEN) {
        if (to.path === '/') {
            return next({ name: 'HomeV2' });
        } else {
            return next();
        }
    } else {
        return next({ name: 'Auth Login' });
    }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;
