var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('titles.notification_templates')))]},proxy:true},(_vm.hasAccess('news', 'create'))?{key:"button",fn:function(){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.createUpdateModal",modifiers:{"createUpdateModal":true}}],staticClass:"ml-auto",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)]},proxy:true}:null],null,true)}),_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkLang(item.title))+" ")]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"line__break"},[_vm._v(_vm._s(_vm.checkLang(item.description)))])]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('div',[(item.image.path)?_c('img',{staticClass:"table-img",attrs:{"src":item.image.path,"alt":""}}):_c('span',[_vm._v("No Photo")])]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('table-action-btns',{attrs:{"delete-access":{ section: 'news', permission: 'delete' },"delete-handler":_vm.deleteItem,"index":index,"item":item,"update-access":{ section: 'news', permission: 'update' }},on:{"openModal":function($event){return _vm.getOneItem(item)}}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"createUpdateModal","hide-footer":_vm.isViewModal,"no-close-on-backdrop":true,"title":_vm.updatingItem ? _vm.$t('edit.notification_template') : _vm.$t('add.notification_template'),"cancel-variant":"danger","centered":"","size":"lg"},on:{"hidden":_vm.beforeClose},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.submitModal}},[_vm._v(" "+_vm._s(_vm.modalSubmitBtnText)+" ")])]},proxy:true}]),model:{value:(_vm.isOpenModal),callback:function ($$v) {_vm.isOpenModal=$$v},expression:"isOpenModal"}},[_c('ValidationObserver',{ref:"createUpdateModal"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.real_title'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.real_title')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.real_title')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.title.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.title, "uz", $$v)},expression:"dataForModal.title.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.real_title'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.real_title')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.real_title')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.title.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.title, "ru", $$v)},expression:"dataForModal.title.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.real_title'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.real_title')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.real_title')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.title.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.title, "en", $$v)},expression:"dataForModal.title.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [UZ] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [UZ]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.description')) + " [UZ]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.description.uz),callback:function ($$v) {_vm.$set(_vm.dataForModal.description, "uz", $$v)},expression:"dataForModal.description.uz"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [RU] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [RU]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.description')) + " [RU]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.description.ru),callback:function ($$v) {_vm.$set(_vm.dataForModal.description, "ru", $$v)},expression:"dataForModal.description.ru"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('titles.description'))+" [EN] ")]),_c('ValidationProvider',{attrs:{"name":((_vm.$t('titles.description')) + " [EN]"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-textarea',{attrs:{"disabled":_vm.isViewModal,"placeholder":((_vm.$t('enter.description')) + " [EN]"),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.description.en),callback:function ($$v) {_vm.$set(_vm.dataForModal.description, "en", $$v)},expression:"dataForModal.description.en"}})],1),_c('span',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.link')))]),_c('b-form-group',[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.link'),"size":"lg"},model:{value:(_vm.dataForModal.link),callback:function ($$v) {_vm.$set(_vm.dataForModal, "link", $$v)},expression:"dataForModal.link"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('titles.link_type')))]),_c('b-form-group',[_c('v-select',{attrs:{"placeholder":_vm.$t('titles.types'),"id":"types","disabled":_vm.isViewModal,"options":_vm.staticLinkTypes,"reduce":function (option) { return option.value; },"label":"label","clearable":false},model:{value:(_vm.dataForModal.link_type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "link_type", $$v)},expression:"dataForModal.link_type"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('titles.types'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('titles.types'),"label-for":"types"}},[_c('b-form-input',{attrs:{"disabled":_vm.isViewModal,"placeholder":_vm.$t('enter.type'),"state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.dataForModal.type),callback:function ($$v) {_vm.$set(_vm.dataForModal, "type", $$v)},expression:"dataForModal.type"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"w-100 mb-2"},[_c('label',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t('titles.image')))]),_c('b-col',{attrs:{"cols":"12"}},[_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"accept":'image/*,.zip',"deletable":true,"errorText":{
                                type: _vm.$t('vue_file_agent.file_type_error'),
                                size: _vm.$t('vue_file_agent.size_type_error')
                            },"helpText":_vm.$t('vue_file_agent.choose_image'),"maxFiles":14,"maxSize":'10MB',"meta":true,"multiple":false,"theme":'grid'},on:{"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)},"select":function($event){return _vm.filesSelected($event)}},model:{value:(_vm.uploadImage),callback:function ($$v) {_vm.uploadImage=$$v},expression:"uploadImage"}}),_c('b-button',{staticClass:"float-right mt-1",attrs:{"disabled":!_vm.fileRecordsForUpload.length || _vm.imageUploaded,"variant":_vm.imageUploaded ? 'success' : 'primary'},on:{"click":function($event){return _vm.getImageId()}}},[(!_vm.imageUploaded)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.upload_image'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('vue_file_agent.image_uploaded'))+" ")])])],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }