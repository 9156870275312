<script>
import { MERCHANT_ID } from '@/constants/names';
import api from '@/services/api';
import { checkLocales } from '@/util/lang';
import NewLoggerTimeLine from '@/components/NewLoggerTimeLine.vue';
import vSelect from 'vue-select';

export default {
    name: 'SSRoadmapScore',
    components: { NewLoggerTimeLine, vSelect },
    data() {
        return {
            chosenSeason: null,
            items: [],
            pagination: {},
            requestPending: false,
            filter: {
                page: 1
            },
            seasonList: []
        };
    },
    async mounted() {
        await this.getCurrentSeason();
        await this.getRoadmapSeasons();
        await this.getRoadmapScore();
    },
    methods: {
        checkLocales,
        async getCurrentSeason() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.roadmapSeasonGtwApi.fetchCurrentSeason(body);
                this.chosenSeason = data.data.id;
            } catch (e) {
                console.error(e);
            }
        },

        async getRoadmapSeasons() {
            const body = {
                page: 1,
                limit: 100,
                merchant_id: parseInt(MERCHANT_ID)
            };
            try {
                const { data } = await api.roadmapSeasonGtwApi.fetchAllItems(body);
                this.seasonList = data.data.list.map((item) => {
                    return {
                        ...item,
                        name: checkLocales(item.name)
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },

        async getRoadmapScore(page) {
            this.requestPending = true;
            this.filter = Object.assign(this.filter, {
                limit: 10,
                merchant_id: parseInt(MERCHANT_ID),
                user_id: this.$route.params.id,
                season_id: this.chosenSeason
            });
            if (page) {
                this.filter.page = page;
            }
            try {
                const { data } = await api.roadmapSeasonGtwApi.fetchRoadmapScore(this.filter);
                if (data.data.pagination.current === 1) {
                    this.items = data.data.list;
                } else {
                    this.items = [...this.items, ...data.data.list];
                }
                this.pagination = data.data.pagination;
                this.requestPending = false;
            } catch (e) {
                console.error(e);
            }
        },

        loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.filter.page = page;
            this.getRoadmapScore();
        }
    }
};
</script>

<template>
    <div class="row">
        <div class="col-12 col-md-6 order-2 order-md-1">
            <new-logger-time-line
                v-model:loading="requestPending"
                :items="items"
                :pagination="pagination"
                @loadNextPage="loadNextPage"
            >
                <template #default="{ item }">
                    <div class="activity-item d-flex justify-content-between">
                        <div class="activity-item__content">
                            <div
                                class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center"
                            >
                                <div class="mb-1">
                                    <span class="text-info font-weight-bold font-medium-2">
                                        {{ checkLocales(item['season'].name) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <var>{{ $t('titles.type') }} : </var>
                                <b-badge variant="primary">{{ item['type'] }} </b-badge>
                            </div>
                            <div>
                                <var>{{ $t('titles.score') }} : </var>
                                <span class="font-medium-1">
                                    <strong>{{ item['scores'] }}</strong>
                                </span>
                            </div>
                        </div>
                        <div>
                            <small class="text-right created__at">
                                {{ item['created_at'] }}
                            </small>
                        </div>
                    </div>
                </template>
            </new-logger-time-line>
        </div>
        <div class="col-12 col-md-6 order-1 order-md-2">
            <div class="card p-1">
                <h3 class="mb-1">{{ $t('filter_title') }}</h3>
                <label for="season">{{ $t('titles.season') }}</label>
                <v-select
                    id="season"
                    :placeholder="$t('titles.season_id')"
                    :options="seasonList"
                    v-model="chosenSeason"
                    :reduce="(option) => option.id"
                    label="name"
                    :clearable="false"
                    @input="getRoadmapScore(1)"
                />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
