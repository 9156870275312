<script>
import api from '@/services/api';
import { BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import vSelect from 'vue-select';
import FilterBlock from '@/components/FilterBlock';
import flatPickr from 'vue-flatpickr-component';
// import { MERCHANT_ID } from '@/constants/names';
import { isBoolean } from '@/util/inspect.util';
import NewLoggerTimeLine from '@/components/NewLoggerTimeLine.vue';
import { MERCHANT_ID } from '@/constants/names';
import { formatDateToYMD } from '@/util/date/calendar.util';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';

export default {
    name: 'SubscribersNewLogger',
    components: {
        AppCollapseItem,
        AppCollapse,
        NewLoggerTimeLine,
        FilterBlock,
        BRow,
        BCol,
        BFormInput,
        BFormGroup,
        vSelect,
        flatPickr
    },
    data() {
        return {
            logs: {
                items: [],
                pagination: {}
            },
            logsDetailsAsArray: [],
            filter: {
                page: 1,
                params: {
                    date_start: '',
                    date_end: '',
                    service: '',
                    status_code: null,
                    user_id: this.$route.params.id
                }
            },
            windowWidth: window.innerWidth,
            requestPending: false,
            localLang: localStorage.getItem('lang')
        };
    },
    computed: {
        serviceList() {
            if (parseInt(MERCHANT_ID) === 1) {
                return [
                    '| MAIN |',
                    'charge-al',
                    'package-al-control',
                    'subscription-al',
                    'randomizer-al',
                    'ussd-al',
                    'level-al-component',
                    '| QUEUE |',
                    'queue-al-service-dispenser',
                    'queue-al-subscription',
                    'queue-al-bonus',
                    'queue-al-daily',
                    'queue-al-premium',
                    'queue-al-infinity',
                    'queue-al-flash',
                    '| OTHERS |',
                    'infinity-al',
                    'infinity-al-purchase',
                    'infinity-al-dispenser',
                    'unknown',
                    '| OLDS |',
                    'bonus-al-gateway',
                    'bonus-al-queue-bonus',
                    'bonus-al-queue-daily',
                    'bonus-al-queue-premium',
                    'bonus-al-queue-flash'
                ];
            } else {
                return [
                    '| MAIN |',
                    'charge-gf',
                    'package-gf-control',
                    'subscription-gf',
                    'randomizer-gf',
                    'ussd-gf',
                    'level-gf-component',
                    '| QUEUE |',
                    'queue-gf-service-dispenser',
                    'queue-gf-subscription',
                    'queue-gf-bonus',
                    'queue-gf-daily',
                    'queue-gf-premium',
                    'queue-gf-infinity',
                    'queue-gf-flash',
                    '| OTHERS |',
                    'infinity-gf',
                    'infinity-gf-purchase',
                    'infinity-gf-dispenser',
                    'unknown',
                    '| OLDS |',
                    'bonus-gf-gateway',
                    'bonus-gf-queue-bonus',
                    'bonus-gf-queue-daily',
                    'bonus-gf-queue-premium',
                    'bonus-gf-queue-flash'
                ];
            }
        }
    },
    methods: {
        isBoolean,

        colorByStatusCode(statusCode) {
            const toFixedNum = parseInt(Math.floor(statusCode / 100).toFixed(0));
            switch (toFixedNum) {
                case 2:
                    return 'success';
                case 3:
                    return 'warning';
                case 4:
                    return 'warning';
                case 5:
                    return 'danger';
                default:
                    return 'secondary';
            }
        },

        async fetchLogs() {
            if (this.requestPending) return;
            this.requestPending = true;
            this.filter = Object.assign(this.filter, {
                limit: 10,
                merchant_id: MERCHANT_ID
            });
            const { data } = await api.coin.fetchNewLogsList(this.filter);
            if (data.data.pagination.current === 1) {
                this.logs.items = data.data.list;
            } else {
                this.logs.items = [...this.logs.items, ...data.data.list];
            }
            this.logs.pagination = data.data.pagination;
            this.requestPending = false;
        },

        filterButtonAction() {
            this.logs.items = [];
            this.filter.page = 1;
            this.fetchLogs();
        },

        loadNextPage(page) {
            if (this.requestPending || this.autoReload || !page) return;
            this.filter.page = page;
            this.fetchLogs();
        },

        resetFilter() {
            this.filter = {
                page: 1,
                params: {
                    date_start: '',
                    date_end: '',
                    service: '',
                    status_code: '',
                    user_id: this.$route.params.id
                }
            };
            this.fetchLogs();
        }
    },
    async mounted() {
        await this.fetchLogs();
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-md-7 col-sm-12 col-12 order-2 order-md-1">
                <new-logger-time-line
                    v-model:loading="requestPending"
                    :items="logs.items"
                    :pagination="logs.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="activity-item">
                            <div class="activity-item__content">
                                <div
                                    class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center"
                                >
                                    <div>
                                        <span class="text-secondary font-small-3"
                                            >|{{ item.service }}|</span
                                        >
                                    </div>
                                    <div class="ml-sm-1">
                                        <span class="font-medium-2"
                                            ><strong>{{ item.title }}</strong></span
                                        >
                                    </div>
                                </div>
                                <div>
                                    <var>STATUS CODE : </var>
                                    <b-badge :variant="colorByStatusCode(item.status_code)"
                                        >{{ item.status_code }}
                                    </b-badge>
                                </div>
                                <!--                                <div class="divider"></div>-->
                                <div v-for="(name, value, index) in item.detail" :key="index">
                                    <var>
                                        <span class="text-uppercase"> {{ value }} : </span>

                                        <span v-if="isBoolean(name)" class="font-medium-2">
                                            <feather-icon
                                                :class="name ? 'text-success' : 'text-danger'"
                                                :icon="name ? 'CheckIcon' : 'XIcon'"
                                                size="20"
                                            />
                                        </span>
                                        <span v-else class="font-medium-2">{{ name }}</span>
                                    </var>
                                </div>
                            </div>
                            <small class="text-right created__at">
                                {{ item.created_at }}
                            </small>
                        </div>
                    </template>
                </new-logger-time-line>
            </b-col>

            <b-col
                v-if="windowWidth > 576"
                class="mb-2 mb-md-0 col-lg-6 col-md-5 col-sm-12 col-12 order-1 order-md-2"
            >
                <filter-block :filter="filter" :show-buttons="false" @submit="fetchLogs">
                    <!--  START DATE  -->
                    <b-col cols="12">
                        <h5>{{ $t('titles.start_date') }}</h5>
                        <b-form-group>
                            <flat-pickr
                                id="datePicker"
                                v-model="filter.params.date_start"
                                :config="{
                                    enableTime: false,
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                autocomplete="off"
                                class="mb-1 form-control"
                                name="date"
                            />
                        </b-form-group>
                    </b-col>

                    <!--  END DATE  -->
                    <b-col cols="12">
                        <h5>{{ $t('titles.end_date') }}</h5>
                        <b-form-group>
                            <flat-pickr
                                id="datePicker"
                                v-model="filter.params.date_end"
                                :config="{
                                    enableTime: false,
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                autocomplete="off"
                                class="mb-1 form-control"
                                name="date"
                            />
                        </b-form-group>
                    </b-col>

                    <!--  SERVICE  -->
                    <b-col class="mb-1" cols="12">
                        <h5>{{ $t('titles.service') }}</h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.params.service"
                                :options="serviceList"
                                :placeholder="$t('choose.service')"
                                :searchable="false"
                                :selectable="(option) => !option.includes('|')"
                                label="label"
                            />
                        </b-form-group>
                    </b-col>

                    <!--  STATUS CODE  -->
                    <b-col class="mb-1" cols="12">
                        <h5>{{ $t('titles.status_code') }}</h5>
                        <b-form-group>
                            <b-form-input
                                v-model="filter.params.status_code"
                                :placeholder="$t('enter.status_code')"
                                type="number"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col class="col-12">
                        <div class="d-flex justify-content-end">
                            <b-button @click="resetFilter">Сбросить</b-button>
                            <b-button class="ml-1" variant="success" @click="filterButtonAction"
                                >Фильтровать
                            </b-button>
                        </div>
                    </b-col>
                </filter-block>
            </b-col>
            <b-col
                v-else
                class="mb-2 mb-md-0 col-lg-6 col-md-5 col-sm-12 col-12 order-1 order-md-2"
            >
                <app-collapse>
                    <app-collapse-item :title="$t('game_history.filter')">
                        <filter-block :filter="filter" :show-buttons="false" @submit="fetchLogs">
                            <!--  START DATE  -->
                            <b-col cols="12">
                                <h5>{{ $t('titles.start_date') }}</h5>
                                <b-form-group>
                                    <flat-pickr
                                        id="datePicker"
                                        v-model="filter.params.date_start"
                                        :config="{
                                            enableTime: false,
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        :placeholder="$t('choose.date')"
                                        autocomplete="off"
                                        class="mb-1 form-control"
                                        name="date"
                                    />
                                </b-form-group>
                            </b-col>

                            <!--  END DATE  -->
                            <b-col cols="12">
                                <h5>{{ $t('titles.end_date') }}</h5>
                                <b-form-group>
                                    <flat-pickr
                                        id="datePicker"
                                        v-model="filter.params.date_end"
                                        :config="{
                                            enableTime: false,
                                            allowInput: true,
                                            time_24hr: true
                                        }"
                                        :placeholder="$t('choose.date')"
                                        autocomplete="off"
                                        class="mb-1 form-control"
                                        name="date"
                                    />
                                </b-form-group>
                            </b-col>

                            <!--  SERVICE  -->
                            <b-col class="mb-1" cols="12">
                                <h5>{{ $t('titles.service') }}</h5>
                                <b-form-group>
                                    <v-select
                                        v-model="filter.params.service"
                                        :options="serviceList"
                                        :placeholder="$t('choose.service')"
                                        :searchable="false"
                                        :selectable="(option) => !option.includes('|')"
                                        label="label"
                                    />
                                </b-form-group>
                            </b-col>

                            <!--  STATUS CODE  -->
                            <b-col class="mb-1" cols="12">
                                <h5>{{ $t('titles.status_code') }}</h5>
                                <b-form-group>
                                    <b-form-input
                                        v-model="filter.params.status_code"
                                        :placeholder="$t('enter.status_code')"
                                        type="number"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col class="col-12">
                                <div class="d-flex justify-content-end">
                                    <b-button @click="resetFilter">Сбросить</b-button>
                                    <b-button
                                        class="ml-1"
                                        variant="success"
                                        @click="filterButtonAction"
                                        >Фильтровать
                                    </b-button>
                                </div>
                            </b-col>
                        </filter-block>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.activity-item {
    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 0.25rem;
        width: 80%;

        h4 {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
        }

        h5 {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #7367f0;
            margin-bottom: 0;
        }

        span {
            //color: #ea5455;

            &.debit {
                color: #28c76f;
            }
        }
    }
}

.divider {
    height: 1px;
    width: 100%;
    background: var(--secondary);
}

@media only screen and (max-width: 450px) {
    .created__at {
        width: min-content;
    }
}
</style>
